<template>
  <TransitionRoot :show="open" as="template" appear>
    <Dialog class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-0 transition-all"
          >
            <Combobox v-model="selected" @update:modelValue="onSelect">
              <div class="grid grid-cols-1">
                <ComboboxInput
                  class="col-start-1 row-start-1 h-12 w-full pl-11 pr-10 text-base text-gray-900 outline-none border-none placeholder:text-gray-400 sm:text-sm"
                  placeholder="Search…"
                  @change="handleSearch"
                />
                <MagnifyingGlassIcon
                  class="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400"
                  aria-hidden="true"
                />
                <div
                  v-if="isSearching"
                  class="pointer-events-none col-start-1 row-start-1 mr-3 self-center justify-self-end"
                >
                  <svg
                    aria-hidden="true"
                    class="size-5 text-blue-600 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                      class="opacity-25"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>

              <ComboboxOptions
                v-if="query === '' || filteredDocuments.length > 0"
                static
                as="ul"
                class="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
              >
                <li class="p-2">
                  <h2
                    v-if="query === ''"
                    class="hidden mb-2 mt-4 px-3 text-xs font-semibold text-gray-500"
                  >
                    Recent searches
                  </h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption
                      v-for="item in query === '' ? recent : filteredDocuments"
                      :key="item.id"
                      :value="item"
                      as="template"
                      v-slot="{active}"
                    >
                      <li
                        :class="[
                          'flex cursor-pointer select-none items-center rounded-md px-3 py-2 ',
                          active && 'bg-gray-100 outline-none border-none'
                        ]"
                        @click="onSelect(item)"
                      >
                        <!-- <FolderIcon
                          :class="[
                            'size-6 flex-none',
                            active ? 'text-white forced-colors:text-[Highlight]' : 'text-gray-400'
                          ]"
                          aria-hidden="true"
                        /> -->
                        <span class="flex-auto truncate"
                          ><span class="font-semibold">{{ item.original_name }}</span
                          ><span>, {{ item.sender_name }}</span></span
                        >
                        <span v-if="active" class="hidden ml-3 flex-none text-blue-100">
                          Jump to...
                        </span>
                        <span v-if="item.similarity_score" class="ml-2 text-xs text-gray-400">
                          {{ Math.round(item.similarity_score * 100) }}% relevant
                        </span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
                <li v-if="query === ''" class="p-2 hidden">
                  <h2 class="sr-only">Quick actions</h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption
                      v-for="action in quickActions"
                      :key="action.shortcut"
                      :value="action"
                      as="template"
                      v-slot="{active}"
                    >
                      <li
                        :class="[
                          'flex cursor-default select-none items-center rounded-md px-3 py-2',
                          active && 'bg-blue-600 text-white outline-none border-none'
                        ]"
                      >
                        <component
                          :is="action.icon"
                          :class="[
                            'size-6 flex-none',
                            active ? 'text-white forced-colors:text-[Highlight]' : 'text-gray-400'
                          ]"
                          aria-hidden="true"
                        />
                        <span class="ml-3 flex-auto truncate">{{ action.name }}</span>
                        <span
                          :class="[
                            'ml-3 flex-none text-xs font-semibold',
                            active ? 'text-blue-100' : 'text-gray-400'
                          ]"
                        >
                          <kbd class="font-sans">⌘</kbd>
                          <kbd class="font-sans">{{ action.shortcut }}</kbd>
                        </span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-if="query !== '' && !isSearching && filteredDocuments.length === 0"
                class="px-6 py-14 text-center sm:px-14"
              >
                <DocumentDuplicateIcon class="mx-auto size-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 text-sm text-gray-500">
                  We couldn't find any items with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid';
import {
  DocumentDuplicateIcon,
  DocumentPlusIcon,
  FolderPlusIcon,
  HashtagIcon
} from '@heroicons/vue/24/outline';
import {debounce} from 'lodash-es';

const documentStore = useDocumentStore();
const {filteredDocuments} = storeToRefs(documentStore);

// Define props
const props = defineProps({
  show: Boolean
});

const toggleModal = () => {
  open.value = !open.value;
};

defineExpose({
  toggleModal
});

// Define reactive variables
const open = ref(props.show);
const selected = ref(null);
const query = ref('');
const recent = ref([]);
const isSearching = ref(false);

// Define constants
const quickActions = [
  {name: 'Add new document…', icon: DocumentPlusIcon, shortcut: 'N', url: '#'},
  {name: 'Add new sender…', icon: FolderPlusIcon, shortcut: 'S', url: '#'},
  {name: 'Add tag…', icon: HashtagIcon, shortcut: 'T', url: '#'}
];

// Create debounced search function
const debouncedSearch = debounce(async searchQuery => {
  if (searchQuery.trim()) {
    try {
      isSearching.value = true;
      await documentStore.searchDocuments(searchQuery);
    } catch (error) {
      console.error('Error searching documents:', error);
    } finally {
      isSearching.value = false;
    }
  }
}, 300);

// Handle search input changes
const handleSearch = event => {
  const value = event.target.value;
  query.value = value;
  if (value) {
    debouncedSearch(value);
  } else {
    documentStore.setFilteredDocuments([]);
  }
};

// Watch for modal visibility
watch(
  () => props.show,
  newVal => {
    open.value = newVal;
    if (!newVal) {
      query.value = '';
      selected.value = null;
    }
  }
);

function onSelect(item) {
  if (item) {
    navigateTo({path: `/app/documents/${item.id}`});
    open.value = false;
  }
}
</script>
