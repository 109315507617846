<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-8 w-auto"
                    src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Your Company"
                  />
                </div>
                <div class="flex items-center py-3">
                  <input
                    type="text"
                    placeholder="Search…"
                    class="w-full rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    disabled
                  />
                  <MagnifyingGlassIcon class="size-5 text-gray-400 ml-2" aria-hidden="true" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a
                            :href="item.href"
                            :class="[
                              item.current
                                ? 'bg-gray-50 text-blue-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                            ]"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                item.current
                                  ? 'text-blue-600'
                                  : 'text-gray-400 group-hover:text-blue-600',
                                'size-6 shrink-0'
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div class="text-xs/6 font-semibold text-gray-400">Your recent documents</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a
                            :href="team.href"
                            :class="[
                              team.current
                                ? 'bg-gray-50 text-blue-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                            ]"
                          >
                            <span
                              :class="[
                                team.current
                                  ? 'border-blue-600 text-blue-600'
                                  : 'border-gray-200 text-gray-400 group-hover:border-blue-600 group-hover:text-blue-600',
                                'flex size-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium'
                              ]"
                              >{{ team.initial }}</span
                            >
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col">
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <div class="flex h-16 shrink-0 items-center">
          <img class="h-9 w-auto" src="assets/img/logo_wide.png" alt="Paperarchive" />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li
              class="flex items-center cursor-pointer gap-x-3 rounded-md p-2 text-sm/6 font-semibold border -mx-2 pl-2 border-gray-200 hover:text-blue-600 group-hover:text-blue-600"
              @click="triggerModal"
            >
              <MagnifyingGlassIcon
                class="pointer-events-none col-start-1 row-start-1 self-center text-gray-400 size-6 shrink-0"
                aria-hidden="true"
              />
              <span
                class="text-sm/6 font-normal text-gray-400 group-hover:text-blue-600 hover:text-blue-600 w-full"
                >Search …</span
              >
            </li>
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    :to="item.href"
                    :class="[
                      isActive(item.href)
                        ? 'bg-gray-50 text-blue-600'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                      'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        isActive(item.href)
                          ? 'text-blue-600'
                          : 'text-gray-400 group-hover:text-blue-600',
                        'size-6 shrink-0'
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li v-if="recentDocuments.length > 0">
              <div class="text-xs/6 font-semibold text-gray-400">Your recent documents</div>
              <ul role="list" class="-mx-2 mt-1 space-y-0.5">
                <li v-for="document in recentDocuments" :key="document.id">
                  <NuxtLink
                    :to="`/app/documents/${document.id}`"
                    :class="[
                      document.current
                        ? 'bg-gray-50 text-blue-600'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                      'flex flex-col gap-y-1 rounded-md p-2 text-sm/6 font-semibold'
                    ]"
                  >
                    <span class="truncate">{{ document.original_name }},</span>
                    <span class="text-xs text-gray-400 truncate -mt-1" v-if="document.sender">{{
                      document.sender.name
                    }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <!-- Footer Section -->
            <div class="mt-8 flex-1"></div>
            <li class="mb-0">
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in footerNavigation" :key="item.name">
                  <NuxtLink
                    :to="item.href"
                    :class="[
                      isActive(item.href)
                        ? 'bg-gray-50 text-blue-600'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                      'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        isActive(item.href)
                          ? 'text-blue-600'
                          : 'text-gray-400 group-hover:text-blue-600',
                        'size-6 shrink-0'
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <!-- User Profile Section -->
            <li class="mt-auto border-t border-gray-200 -mx-6 px-6 py-2 hover:bg-gray-50">
              <Menu as="div" class="relative">
                <div>
                  <MenuButton
                    class="flex items-center w-full gap-x-4 px-0 py-3 text-sm font-semibold text-gray-900"
                  >
                    <UserCircleIcon class="hidden size-6 rounded-full bg-gray-50 text-gray-400" />
                    <div class="flex-1 text-left truncate">
                      <div class="text-gray-400 text-xs">Signed in as</div>
                      <div class="text-sm font-semibold text-gray-700 truncate">
                        {{ userEmail }}
                      </div>
                    </div>
                    <ChevronUpIcon class="size-5 text-gray-400" aria-hidden="true" />
                  </MenuButton>
                </div>
                <Transition
                  enter="transition ease-out duration-100"
                  enter-from="transform opacity-0 scale-95"
                  enter-to="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leave-from="transform opacity-100 scale-100"
                  leave-to="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute bottom-full right-0 mb-1 w-60 bg-white shadow-lg rounded-md py-1"
                  >
                    <MenuItem v-slot="{active}">
                      <NuxtLink
                        to="/app/profile"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                      >
                        Profile
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem v-slot="{active}" class="py-1 border-t border-gray-200">
                      <NuxtLink
                        to="/app/support"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                      >
                        Support
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem v-slot="{active}">
                      <NuxtLink
                        to="/app/changelog"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                      >
                        Changelog
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem v-slot="{active}">
                      <NuxtLink
                        to="/app/terms"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                      >
                        Terms
                      </NuxtLink>
                    </MenuItem>
                    <MenuItem v-slot="{active}" class="py-1 border-t border-gray-200">
                      <NuxtLink
                        to="/app/logout"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                      >
                        Logout
                      </NuxtLink>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="size-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm/6 font-semibold text-gray-900">Dashboard</div>
      <a href="#">
        <span class="sr-only">Your profile</span>
        <img
          class="size-8 rounded-full bg-gray-50"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </a>
    </div>

    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>

    <SearchModal ref="searchModal" :show="false" />
  </div>
</template>

<script setup>
import SearchModal from '@/components/SearchModal.vue';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import {
  Bars3Icon,
  ChevronUpIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  TagIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline';
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';

const route = useRoute();
const authStore = useAuthStore();
const documentStore = useDocumentStore();
const {recentDocuments} = storeToRefs(documentStore);
const {user} = storeToRefs(authStore);
const sidebarOpen = ref(false);
const searchModal = ref(null);

const userEmail = computed(() => user.value?.email || '');

const navigation = [
  {name: 'Documents', href: '/app/documents', icon: DocumentDuplicateIcon},
  {name: 'Senders', href: '/app/senders', icon: UsersIcon},
  {name: 'Tags', href: '/app/tags', icon: TagIcon}
];

const footerNavigation = [
  {name: 'Settings', href: '/app/settings', icon: Cog6ToothIcon}
];

function isActive(href) {
  return route.path.startsWith(href);
}

const triggerModal = () => {
  searchModal.value?.toggleModal();
};

// Add event listener for cmd+k or ctrl+k
onMounted(() => {
  const handleKeydown = event => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      searchModal.value?.toggleModal();
    }
  };

  window.addEventListener('keydown', handleKeydown);

  // Cleanup event listener on unmount
  onUnmounted(() => {
    searchModal.value = null;
    window.removeEventListener('keydown', handleKeydown);
  });
});
</script>
